<template>
    <!-- 常用旅客列表 -->
    <div class="passenger-cont">
        <div class="head-background">
            <span class="title">常用旅客</span>
        </div>
        <!-- 未绑定随行人员 -->
        <div v-for="(data,i) in tavelList12306.noList" :key="data.usercode" class="passengerItem">
            <div class="list-text-big">{{data.username}}</div>
            <div class="list-text default">身份证：{{data.idCard}}</div>
            <div class="list-text warning">未绑定人员</div>
            <van-button type="primary" :loading="loadings[i]" size="small" class="btn" @click="submit(data,i)">添加</van-button>
        </div>
        <div class="gap"></div>

        <!-- 12306已绑定旅客 -->
        <div v-for="data in tavelList12306.travelLlist" :key="data.certNo" class="passengerItem">
            <div class="list-text-big">{{data.passengerName}}</div>
            <div class="list-text default">身份证：{{data.certNo}}</div>
            <div class="list-text green" v-if="data.canBuyNow=='Y'">联系方式已审核</div>
            <div class="list-text warning" v-else>联系方式待审核</div>
        </div>
        <div class="gap"></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { addTraveler} from '@/api/train'
export default {
  name: "passengerList",
  computed: {
    ...mapGetters(["tavelList12306"]),
  },
  data() {
    return {
      loadings: [], // 人员列表
    };
  },
  mounted(){
    for (let i = 0; i < this.tavelList12306.noList.length; i++) {
      this.loadings.push(false)
    }
  },
  methods: {
    // 添加常旅客
    addAxios(data,i){
      let req = JSON.parse(window.localStorage.getItem("t12306"))
      req.corp = this.tavelList12306.corp
      req.passengerName = data.username
      req.sexCode = (data.gender=='男'?'M':'F')
      req.certNo = data.idCard||''+''
      req.certType = '1'
      req.passengeType = '1'
      req.mobileNo = data.tel||''+''
      req.phoneNo = ''
      req.email = data.mail||''
      req.address = ''
      req.postalCode = ''
      req.bornDate = data.birthday||''
      addTraveler(req)
      .then(res=>{
        this.loadings[i]=false
        this.$forceUpdate()
        if(res.success) this.$toast.success('添加成功！如有延迟，请耐心等待。')
      }).catch(()=>{
        this.loadings[i]=false
        this.$forceUpdate()
      })
    },

    // 提交提示
    submit(data,i){
      this.$dialog.confirm({
        title: '提示',
        message: '绑定后的“常旅客”只能在12306中修改，是否继续？',
      })
        .then(() => {
          this.loadings[i]=true
          this.$forceUpdate()
          this.addAxios(data,i)
        })
      }
    },


};
</script>

<style lang="less" scoped>
@import '../../styles/theme.less';
.passengerItem{
    padding: 0.2rem 0.6rem;
    border-bottom: 1px solid @gray-3;
    position: relative;
}
.btn{
  position: absolute !important;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>